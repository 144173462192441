<template>
  <a-card :bordered="false" hoverable>
    <div class="layout">
      <div class="layout-left">
        <a-menu mode="inline" class="layout-left-menu" :selectedKeys="active">
          <a-menu-item key="/settings/index">
            <router-link :to="{ path: 'index' }" replace>
              系统设置
            </router-link>
          </a-menu-item>
          <a-menu-item key="/settings/notice">
            <router-link :to="{ path: 'notice' }" replace>
              公告设置
            </router-link>
          </a-menu-item>
          <a-menu-item key="/settings/holiday">
            <router-link :to="{ path: 'holiday' }" replace>
              假期设置
            </router-link>
          </a-menu-item>
        </a-menu>
      </div>
      <div class="layout-right">
        <div class="layout-right-title">
          <span>{{ $route.meta.title }}</span>
        </div>
        <router-view />
      </div>
    </div>
  </a-card>
</template>

<script>
export default {
  computed: {
    active() {
      let path = this.$route.path
      const arr = path.split("/")
      if (arr.length > 3) {
        path = "/" + arr[1] + "/" + arr[2]
      }
      return [path]
    }
  }
}
</script>

<style scoped>
.ant-card {
  min-height: 100%;
  padding: 16px;
}

.ant-card-body {
  min-height: 100%;
}

.layout {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
}

.layout-left {
  border-right: 1px solid #e8e8e8;
  width: 300px;
}

.layout-left-menu {
  border: 0;
  width: auto;
}

.layout-right {
  flex: 1 1;
  padding: 8px 40px 30px;
}

.layout-right-title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 12px;
}
</style>
