<template>
  <a-menu
    theme="dark"
    mode="inline"
    :selectedKeys="active"
    @click="handleClick"
  >
    <template v-for="item in routes">
      <template v-if="!item.redirect && !item.meta.hidden">
        <a-menu-item v-if="!item.children" :key="item.path">
          <a-icon :type="item.meta.icon" />
          <span>{{ item.meta.title }}</span>
        </a-menu-item>
        <a-sub-menu v-else :key="item.path">
          <span slot="title"
            ><a-icon :type="item.meta.icon" /><span>{{
              item.meta.title
            }}</span></span
          >
          <template v-for="sub in item.children">
            <template v-if="!sub.redirect && !sub.meta.hidden">
              <a-menu-item :key="sub.path">
                <a-icon :type="sub.meta.icon" />
                <span>{{ sub.meta.title }}</span>
              </a-menu-item>
            </template>
          </template>
        </a-sub-menu>
      </template>
    </template>
  </a-menu>
</template>

<script>
import { getMenuRoutes } from "../router"

export default {
  methods: {
    handleClick(e) {
      this.$router.replace(e.key)
      this.goTop()
    },
    goTop() {
      //返回浏览器顶部
      let distance =
        document.documentElement.scrollTop || document.body.scrollTop
      let step = distance / 30
      ;(function jump() {
        if (distance > 0) {
          distance -= step
          window.scrollTo(0, distance)
          setTimeout(jump, 10)
        }
      })()
    }
  },
  computed: {
    routes() {
      return getMenuRoutes(this.$store.getters.getState.permission)
    },
    active() {
      return [this.$route.path]
    }
  }
}
</script>

<style scoped></style>
