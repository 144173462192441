<template>
  <div class="layout">
    <div class="layout-container">
      <div class="layout-header">
        <div class="layout-header-title">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-Island"></use>
          </svg>
          <span class="layout-header-title-text">AMOB OMS</span>
        </div>
        <div class="layout-header-title-des">
          我们的小岛网站订单管理系统
        </div>
      </div>
      <div class="layout-content">
        <router-view />
      </div>
      <div class="layout-footer">
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import Footer from "./Footer.vue"
export default {
  components: { Footer }
}
</script>

<style scoped>
.layout {
  height: 100%;
  min-height: 100%;
  background: #f0f2f5 url(~@/assets/background.svg) no-repeat 50%;
  background-size: 100%;
  border: 1px solid white;
}

.layout-container {
  margin: 0 auto;
}

.layout-header {
  margin-top: 60px;
  margin-bottom: 40px;
  text-align: center;
}

.layout-header-title {
  height: 70px;
  line-height: 70px;
}

.layout-header-title > svg {
  font-size: 70px;
  margin-right: 12px;
}

.layout-header-title-text {
  font-size: 30px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: lighter;
}

.layout-header-title-des {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  margin-top: 12px;
  margin-bottom: 40px;
  letter-spacing: 1px;
}

.layout-content {
  min-width: 260px;
  width: 350px;
  margin: 0 auto;
}

.layout-footer {
  margin-top: 50px;
}
</style>
