<template>
  <div class="copyright">
    Copyright © 2020 amob.cn ·
    <a href="https://beian.miit.gov.cn" target="_blank">鲁ICP备14021414号-5</a>
    <p>{{ getVersion }}</p>
  </div>
</template>

<script>
import * as config from "@/config"

export default {
  computed: {
    getVersion() {
      return config.APP_VERSION
    }
  }
}
</script>

<style scoped>
.copyright {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  text-align: center;
}
</style>
