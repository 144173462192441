<template>
  <a-layout class="layout">
    <a-layout-sider breakpoint="lg" collapsed-width="0">
      <div class="layout-logo">
        <div class="layout-logo-title">订单管理系统</div>
        <div class="layout-logo-desc">{{ app_name }}</div>
      </div>
      <Menu />
    </a-layout-sider>
    <a-layout>
      <a-layout-header class="layout-header">
        <Header />
      </a-layout-header>
      <a-layout-content>
        <div class="layout-content">
          <keep-alive>
            <router-view
              :key="active"
              v-if="$route.meta.keepAlive"
            ></router-view>
          </keep-alive>
          <router-view
            :key="active"
            v-if="!$route.meta.keepAlive"
          ></router-view>
        </div>
      </a-layout-content>
      <a-layout-footer>
        <Footer />
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>

<script scoped>
import * as config from "@/config"
import Footer from "./Footer.vue"
import Header from "./Header.vue"
import Menu from "./Menu.vue"
export default {
  data() {
    return {
      app_name: config.APP_NAME
    }
  },
  components: { Header, Menu, Footer },
  computed: {
    active() {
      //防止路由不刷新
      return this.$route.path
    }
  },
  methods: {
    getNotice() {
      if (
        !this.$cookies.get("notice_hide") &&
        this.$store.getters.getState.permission != config.PERMISSION_ADMIN
      ) {
        this.$request({
          url: "/getNotice",
          method: "get"
        }).then(res => {
          if (res.data.notice_show) {
            const now_date = new Date(
              this.$moment().format("YYYY-MM-DD")
            ).getTime()
            const end_date = new Date(res.data.notice_end_date).getTime()
            if (now_date < end_date) {
              this.$modal.info({
                title: res.data.notice_title,
                content: res.data.notice_content,
                onOk: () => {
                  this.$cookies.set("notice_hide", "true", "12h")
                }
              })
            }
          }
        })
      }
    }
  },
  mounted() {
    this.getNotice()
  }
}
</script>
<style scoped>
.layout {
  min-height: 100vh;
}

.layout-logo {
  color: #ffffffea;
  font-weight: 350;
  margin: 18px 0px 12px 24px;
}

.layout-logo-title {
  font-size: 18px;
  line-height: 18px;
}

.layout-logo-desc {
  font-size: 10px;
  line-height: 10px;
  margin-top: 2px;
}

.layout-header {
  background: #ffffff;
  padding: 0;
}

.layout-content {
  margin: 10px 10px 0 10px;
  height: 100%;
  min-height: 100%;
}
</style>
