<template>
  <div>
    <div class="layout-left">
      <Header-button icon="reload" title="刷新页面" :callback="reload" />
      <Header-button
        v-if="isTravelAgency"
        icon="audit"
        title="账单"
        :callback="goBillList"
      />
      <Header-button
        v-if="isStaff"
        icon="reconciliation"
        title="订单记录"
        :callback="goOrderRecord"
      />
    </div>
    <div class="layout-right">
      <Avatar-dropdown :username="username" :admin="isAdmin" />
      <Header-button icon="export" :callback="handleLogout" />
    </div>
  </div>
</template>

<script>
import * as config from "@/config"
import AvatarDropdown from "../components/header/AvatarDropdown.vue"
import HeaderButton from "../components/header/HeaderButton.vue"
import { logout } from "@/utils/auth-utils"

export default {
  components: { AvatarDropdown, HeaderButton },
  computed: {
    username() {
      return this.$store.getters.getState.username
    },
    isStaff() {
      return !(
        this.$store.getters.getState.permission === config.PERMISSION_COMMON
      )
    },
    isAdmin() {
      return this.$store.getters.getState.permission === config.PERMISSION_ADMIN
    },
    isTravelAgency() {
      return (
        this.$store.getters.getState.permission === config.PERMISSION_COMMON
      )
    }
  },
  methods: {
    reload() {
      this.$router.go(0)
    },
    goBillList() {
      this.$router.replace("/travel-agency/bill-list")
    },
    goOrderRecord() {
      this.$router.replace("/order/record-list")
    },
    handleLogout() {
      this.$modal.confirm({
        title: "系统提示",
        content: "您确定要退出系统吗？",
        onOk: () => {
          logout()
          this.$router.replace("/user/login")
        },
        onCancel() {}
      })
    }
  }
}
</script>

<style scoped>
.layout-left {
  float: left;
}

.layout-right {
  float: right;
}
</style>
