<template>
  <div id="header-button" @click="click">
    <a-icon :type="icon" class="header-button-icon" />
    <span v-if="title" class="header-button-title">{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: "HeaderButton",
  props: {
    icon: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    callback: {
      type: Function,
      default: null
    }
  },
  methods: {
    click() {
      this.callback()
    }
  }
}
</script>

<style scoped>
#header-button {
  display: inline-block;
  height: 100%;
  cursor: pointer;
  padding: 0 15px;
}
#header-button:hover {
  background: #eeeeee8e;
}

.header-button-icon {
  color: rgba(53, 53, 53, 0.74);
  font-size: 12px;
}

.header-button-title {
  margin-left: 6px;
  font-size: 12px;
  font-weight: lighter;
}
</style>
