<template>
  <a-dropdown v-if="username" placement="bottomRight" id="avatar-dropdown">
    <span class="avatar-dropdown-view">
      <a-avatar :src="avata" class="avatar-dropdown-avatar" />
      <span class="avatar-dropdown-text">{{ username }}</span>
    </span>
    <template v-slot:overlay>
      <a-menu class="avatar-dropdown-menu">
        <a-menu-item @click="handleToChangePassword">
          <a-icon type="lock" />
          修改密码
        </a-menu-item>
        <a-menu-item v-if="admin" @click="handleToSettings">
          <a-icon type="setting" />
          系统设置
        </a-menu-item>
        <a-menu-divider />
        <a-menu-item @click="handleLogout">
          <a-icon type="logout" />
          退出登录
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
  <span v-else>
    <a-spin size="small" class="avatar-dropdown-spin" />
  </span>
</template>

<script>
import { logout } from "@/utils/auth-utils"
export default {
  name: "AvatarDropdown",
  props: {
    username: {
      type: String,
      default: ""
    },
    admin: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    avata() {
      return require("@/assets/img/avata.jpg")
    }
  },
  methods: {
    handleToChangePassword() {
      this.$router.replace("/change-password")
    },
    handleToSettings() {
      this.$router.replace("/settings")
    },
    handleLogout() {
      this.$modal.confirm({
        title: "系统提示",
        content: "您确定要退出系统吗？",
        onOk: () => {
          logout()
          this.$router.replace("/user/login")
        }
      })
    }
  }
}
</script>

<style scoped>
#avatar-dropdown {
  display: inline-block;
  cursor: pointer;
}

#avatar-dropdown:hover {
  background: #eeeeee8e;
}

.avatar-dropdown-view {
  padding: 0 30px;
}

.avatar-dropdown-avatar {
  margin-right: 10px;
}

.avatar-dropdown-text {
  font-size: 14px;
  font-weight: lighter;
}

.avatar-dropdown-spin {
  padding: 0 30px;
}

.ant-avatar {
  border-width: 1.8px;
  border-color: rgba(50, 163, 255, 0.65);
  border-style: solid;
}

.ant-dropdown-menu {
  border-radius: 2px;
}

.ant-dropdown-menu-item {
  min-width: 130px;
  font-size: 13px;
  font-weight: lighter;
}
</style>
